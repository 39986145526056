.redes-sociais{
	@include get-proppertys($redesSociais);

	& > *{
		position:relative;

		&:after{
			content:'';
			display:block;
			opacity:0;
			z-index:1;
			position:absolute;
			top:calc(0% + 9px);
			@include gradient-vertical(#FFFFFF,$theme2);
			// $afterDefault: map-get($redesSociais, 'afterDefault');
			// @include get-proppertys($afterDefault);
			left:calc(0% + 9px);
			width:0.6em;
			height:0.6em;
			border-radius:100%;
		}

		&:hover{
			color:inherit;

			&:after{
				animation:bum 1s linear infinite;
			}

			&.icon-instagram:after{
				$hoverInstagram: map-get($redesSociais, 'hoverInstagram');
				@include get-proppertys($hoverInstagram);
			}

		}

	}

	.fa{
		border-radius:0;
		font-size:18px;
		width:1.5em;
		height:1.5em;
		line-height:(1.5em + 0.1em);
		text-align:center;

		&:first-child{
			margin-left:0;
		}

		&:last-child{
			margin-right:0;
		}

	}

	.fa-facebook{
		$facebook: map-get($redesSociais, 'facebook');
		@include get-proppertys($facebook);
	}

	.fa-twitter{
		$twitter: map-get($redesSociais, 'twitter');
		@include get-proppertys($twitter);
	}

	.fa-youtube{
		$youtube: map-get($redesSociais, 'youtube');
		@include get-proppertys($youtube);
	}

	.icon-instagram{

	}

	&.redes-sociais-topo{
		$redesTopo: map-get($redesSociais, 'redesTopo');
		@include get-proppertys($redesTopo);

		& > *{
			margin:0 2px;
		}

	}

	&.redes-sociais-rodape{
		$redesRodape: map-get($redesSociais, 'redesRodape');
		@include get-proppertys($redesRodape);

		& > *{
			margin:0 9px;

			&:after{
				@include gradient-vertical($theme,$theme2);
			}

		}

	}

}