.form-custom{
	@include get-proppertys($formCustom);

	.input-group{
		border-radius:30px;
		overflow:hidden;
		transition:box-shadow 0.5s ease-out;

		.form-control{
			box-shadow:none;
		}

		.input-group-btn{
			border-radius:100%;
		}

	}

	&.form-custom-topo{
		$formCustomTopo: map-get($formCustom, 'formCustomTopo');
		@include get-proppertys($formCustomTopo);

		.input-group{

			.form-control{
				$formControl: map-get($formCustomTopo, 'formControl');
				@include get-proppertys($formControl);
				font-size:14px;
				font-family: 'poppinsregular';

				@include placeholdIt{
					$placeholder: map-get($formControl, 'placeholder');
					@include get-proppertys($placeholder);
					font-family: 'poppinsregular';
				}

			}

			.btn.btn-buscas{
				$btn: map-get($formCustomTopo, 'btn');
				@include get-proppertys($btn);
				padding:3px 16px;
				outline:none;
			}

			&.focado{
				$inputGroupFocado: map-get($formCustomTopo, 'inputGroupFocado');
				@include get-proppertys($inputGroupFocado);
			}

		}

	}

}