@keyframes btnManipuladorDasSombras{

	0%{
		box-shadow:0 0 0 2px rgba(#444444, 0.5);
	}

	100%{
		box-shadow:0 0 0 4px rgba(#444444, 1);
	}

}