// what the internals have in comum
.internas{
	padding-bottom:0;

	.secao-internas{
		background-color:$theme;
		color:#FFFFFF;
		text-align:center;
		padding-bottom:40px;
		padding-top:40px;

		.onda-leite{
			background-color:inherit;
			margin-bottom:35px;
			animation:none;
			display:none;
		}

		.title-internas{
			margin-bottom:0;
			position:relative;
			font-size:35px;
			@extend .pops-reg;

			&:after{
				content:'';
				display:block;
				max-width:132px;
				min-height:8px;
				position:relative;
				margin-left:auto;
				margin-right:auto;
				background-color:#FFED00;
				margin-top:5px;
			}

		}

	}

	.conteudo-internas{
		padding-top:35px;
		text-align:center;
		padding-bottom:70px;
	}

	&.internas-institucional{

		.conteudo-internas{
			padding-top:50px;
		}

	}

	&.internas-produtos{

		.conteudo-internas{
			padding-top:40px;
			padding-bottom:135px;
		}

	}

	&.internas-produtos-detalhes{

		.conteudo-internas{
			padding-top:40px;
			padding-bottom:210px;
		}

	}

	&.internas-receitas{

		.conteudo-internas{
			padding-top:40px;
			padding-bottom:110px;
		}

	}

	&.internas-receitas-detalhes{

		.conteudo-internas{
			padding-top:30px;
			padding-bottom:100px;
		}

	}

	&.internas-catalogo{

		.conteudo-internas{
			padding-top:60px;
			padding-bottom:200px;
		}

	}

	&.internas-contato{

		.conteudo-internas{
			padding-top:25px;
			padding-bottom:70px;

			.lazyiframe{
				min-height:401px;
				margin-bottom:70px;
			}

		}

	}

	&.internas-trabalhe-conosco{

		.conteudo-internas{
			padding-top:25px;
			padding-bottom:200px;
		}

	}

	

}