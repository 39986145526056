#form-trabalhe-conosco,
#form-contato{

	.form-control{
		border-color:#DDDDDD;
		color:#333333;
		border-radius:0;
		min-height:45px;
		padding-left:17px;
		font-size:14px;
		box-shadow:0 0 0 0 rgba($theme2, 0);
		transition:box-shadow 0.5s linear;

		@include placeholdIt{
			color:#333333;
		}

		&:focus{
			box-shadow:0 0 0 4px rgba($theme2, 1);
		}

	}

	textarea{
		resize:none;
	}

	button{
		font-weight:bold;
		margin-top:5px;
	}


}