#irtopo{
	position:fixed;
	bottom:40px;
	right:20px;
	font-size:25px;
	width:2em;
	height:2em;
	line-height:2em;
	z-index:998;
	border:none;
	@include get-proppertys($irtopo);
	transition:all 0.5s linear;
	opacity:0;
	display:none;

	&:hover,
	&:focus{
		$hover: map-get($irtopo, 'hover');
		@include get-proppertys($hover);
	}

	&.voltou{
		animation:fadeInRight 1s ease-out;
		opacity:1;
	}

	@media(min-width:$min-md){
		display:block;
	}

}