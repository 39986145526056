$br: 0px 0px 29px 20px;
$en: -39px 0px 29px 20px;
$es: 0px -30px 29px 20px;

$icons: (
	'br': 0px 0px 29px 20px,
	'en': -39px 0px 29px 20px,
	'es': 0px -30px 29px 20px,
);


@mixin sprite-width($sprite) {
	width: nth($sprite, 3);
}
@mixin sprite-height($sprite) {
	height: nth($sprite, 4);
}
@function sprite-width($sprite) {
	@return nth($sprite, 3);
}
@function sprite-height($sprite) {
	@return nth($sprite, 4);
}
@mixin sprite-position($sprite) {
	$sprite-offset-x: nth($sprite, 1);
	$sprite-offset-y: nth($sprite, 2);
	background-position: $sprite-offset-x $sprite-offset-y;
}
@mixin sprite($sprite, $display: block) {
	@include sprite-position($sprite);
	background-repeat: no-repeat;
	overflow: hidden;
	display: $display;
	@include sprite-width($sprite);
	@include sprite-height($sprite);
}
.icon {
	background-image: url(../imagens/estrutural/sprite.png);
	display: inline-block;

	@each $icon,$value in $icons {
		&.#{$icon} {
			width: nth($value,3);
			height: nth($value,4);
			background-position: #{nth($value,1)} #{nth($value,2)};
		}
	}
}
