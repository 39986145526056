.banner{
	text-align:center;
	@include get-proppertys($bannerSection);
}

.bem-vindo{
	padding-top:80px;
	padding-bottom:65px;
	text-align:center;
	@include get-proppertys($bemVindoSection);
	@extend .sections-com-background;
}

.produtos{
	padding-top:40px;
	text-align:center;
	@include get-proppertys($produtosSection);
	position:relative;
	background-size:cover;
	background-position:center center;
	background-repeat:no-repeat;

	@media(max-width:$max-md){

		.row{
			display:flex;
			flex-wrap:wrap;

			& > *{
				width:100%;
			}

		}

	}

}