.card-produtos-internas,
.card-receitas-internas{
	text-align:center;
	font-size:16px;

	.card-title{
		padding:0 20px;
		margin-bottom:25px;
		color:#444444;
		@extend .pops-reg;
	}

	.btn{
		color:#444444;
		transition:color 0.5s linear;
		@extend .pops-bld;

		&:hover,
		&:focus{
			color:#00279B;
			text-decoration:none;
		}

	}


}