.form-incrivel{
	
	.input-group{
		@include flexbox;
		align-items:stretch;
		width:100%;

		.form-control{
			flex-grow:1;
			width:1%;
			@include flexbox;
			float:none;
			height:inherit;
		}

		.input-group-btn{
			@include flexbox;
			white-space:normal;
			width:unset;
		}

	}

}