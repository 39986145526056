.rodape{
	background-size:cover;
	background-position:center center;
	background-repeat:no-repeat;
	padding-top:35px;
	padding-bottom:20px;
	text-align:center;
	@include get-proppertys($rodape);
	@extend .pops-reg;

	.content__editable{
		width: 100%;
		max-width: 900px;
		margin-right: auto;
		margin-left: auto;
	}

	.contato-legal{
		margin-bottom:35px;
	}

	.redes-sociais{
		margin-bottom:260px;
	}

	.copyright{
		text-align:right;
	}

	.logo-rodape{
		margin-bottom:15px;
	}

	a:hover .icon-gv8{
	    transform:translateY(-5px);
	}

}