@keyframes telGrow{

	0%{
		transform:rotate(0deg) scale(1);
	}

	100%{
		transform:rotate(29deg) scale(1.2);
	}

}