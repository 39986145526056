@media (min-width:$min-md) and (max-width:$max-md){

	.container{
		max-width:720px;
	}

	.row.flex{
		display:flex;
		flex-wrap:wrap;
		justify-content:center;
	}

}