a{
	color:inherit;
	text-decoration:inherit;
	transition:all 0.5s ease-out;
}

a:hover{
	text-decoration:inherit;
}

.paginacao{
	text-align: center;
}

.sections-com-background{
	background-repeat:no-repeat;
	background-size:cover;
	background-attachment:fixed;
	background-position:center;
}

.cor-tema{
	color:$theme;
}

.internas img{
	@extend .img-responsive;
}

.owl-carousel .owl-item img{
	display:inline-block !important;
}

.title-internas{
	margin-top:0;
}

body{
	font-family:'calibri', helvetica , arial, sans-serif;
	color:#444444;
	font-size:16px;
	line-height:1.4;
}

.svg-resetado{
	display:block;
	padding:0;
	margin:0;
	border:none;
}

.wrapper{
	overflow:hidden;
}

.first-mobile{

	@media(max-width:$max-md){
		order:1;
	}

}

.second-mobile{

	@media(max-width:$max-md){
		order:2;
	}

}

.owl-carousel{

	.owl-nav > *,
	.owl-dots > *{
		cursor:pointer;
	}

}

.parallax{
	background-size:cover;
}

.clearfix{

	.img-flotada{
		float:left;
	}

	@media(max-width:$max-sm){
		text-align:center;

		.img-flotada{
			margin-right:0 !important;
			margin-left:0 !important;
			text-align:center;
			float:none;
		}

	}

}

@include text-emphasis-variant('.text-white',#FFF);


// USED FONTS
.amatic-bld{
	font-family: 'amatic_scbold';
}

.calibri{
	font-family: 'calibri';
}

.lovely-reg{
	font-family: 'lovely_homeregular';
}

.opns-lgt{
	font-family: 'open_sanslight';
}

.opns-reg{
	font-family: 'open_sansregular';
}

.pops-bld{
	font-family: 'poppinsbold';
}

.pops-reg{
	font-family: 'poppinsregular';
}

.pops-semi-bld{
	font-family: 'poppinssemibold';
}

.pops-lgt{
	font-family: 'poppinslight';
}

.talvez-11{
	// font-size:11px;
}

.talvez-12{
	// font-size:12px;
}

.title-internas-custom{
	font-size:20px;
	@extend .pops-bld;
	color:#444444;
	margin:0;
}