@media (min-width:$min-xl){

	.container{
		max-width:1170px;
	}

	.row.flex{
		display:flex;
		flex-wrap:wrap;
		justify-content:center;
	}

}