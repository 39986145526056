.topo{
	@include get-proppertys($topo);
	font-family: 'poppinsregular';

	.onda-leite{
		margin-bottom:30px;
		@include get-proppertys($topo);
		transition:all 0.5s linear;
	}

	.nav-content{
		$navContent: map-get($topo, 'navContent');
		@include get-proppertys($navContent);

		@media(min-width:$min-md) and (max-width:$max-md){
			max-width:720px;
		}

		@media(min-width:$min-lg) and (max-width:$max-lg){
			max-width:960px;
		}

		@media(min-width:$min-xl){
			max-width:1170px;
		}

		.bar-menu{
			$barMenu: map-get($navContent, 'barMenu');
			@include get-proppertys($barMenu);

			&:before,
			&:after{
				$barMenu: map-get($navContent, 'barMenu');
				@include get-proppertys($barMenu);
			}

			#menu-topo{
				margin-bottom:0;

				& > li{
					display:block;

					& > a{
						display:inline-block;
					}

				}

				& > li.active > a,
				& > li:hover > a{
					$liAtiva: map-get($barMenu, 'liAtiva');
					@include get-proppertys($liAtiva);
					font-family: 'poppinsbold';

					&:before{
						$beforeAtivo: map-get($liAtiva, 'beforeAtivo');
						@include get-proppertys($beforeAtivo);
						width:100%;
					}

				}

			}

			#form-busca{
				@extend .form-incrivel;
				@extend .form-custom;
				@extend .form-custom-topo;
			}

		}

	}

}

.idiomas{
	width: 100%;
	margin-left:auto;
	margin-right:auto;
	text-align: right;
	padding: 10px 15px;

	.icon ~ .icon{
		margin-left: 10px;
	}

	@media (max-width: $max-sm) {
		border-bottom: 1px solid rgba(#000,.2);
	}

	@media(min-width:$min-md) and (max-width:$max-md){
		max-width:720px;
	}

	@media(min-width:$min-lg) and (max-width:$max-lg){
		max-width:960px;
	}

	@media(min-width:$min-xl){
		max-width:1170px;
	}
}

@media (max-width: $max-sm) {
	.topo {
		.onda-leite{
			display: none !important;
		}
	}
}