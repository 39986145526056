@keyframes bum{

	0%{
		opacity:0.6;
		transform:scale(0);
	}

	100%{
		opacity:0;
		transform:scale(6);
	}

}