@media (min-width:$min-sm) and (max-width:$max-sm) {

	.container{
		max-width:540px;
	}

	.row.flex{
		text-align:center;
	}

	
}