.card-produtos-internas{
	margin-bottom:45px;

	.card-header{
		margin-bottom:15px;
		min-width:270px;
		min-height:326px;
		border:1px solid #eeeeee;
		display:flex;
		justify-content:center;
		align-items:center;
		flex-wrap:wrap;	
	}

}