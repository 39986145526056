.topo{

	@media(max-width:$max-md){

		.mbl-controls{
			display:flex;
			align-items:flex-end;
			justify-content:space-between;
			$mblControls: map-get($topo, 'mblControls');
			@include get-proppertys($mblControls);
			padding:5px 10px;

			.bg-menu{
				position:fixed;
				top:0;
				left:0;
				width:100%;
				$bgMenu: map-get($mblControls, 'bgMenu');
				@include get-proppertys($bgMenu);
			}

			.logo-mobile{
				max-width:35%;
				width:90px;
			}

			#toggleMenu{
				border:none;
				font-size:26px;
				transition:color,background 0.5s ease-in;
				$toggleMenu: map-get($mblControls, 'toggleMenu');
				@include get-proppertys($toggleMenu);
				outline:none;

				&:hover,
				&:focus{
					$hoverFocus: map-get($toggleMenu, 'hoverFocus');
					@include get-proppertys($hoverFocus);
				}

			}

		}

		.nav-content{
			position:fixed;
			left:-300px;
			width:220px;
			height:100%;
			top:0;
			transition:left 0.5s ease-out;
			z-index:1000;
			overflow:auto;
			$navContentMobile: map-get($topo, 'navContentMobile');
			@include get-proppertys($navContentMobile);

			.flex{
				@include flexbox;
				justify-content:center;

				& > *{
					width:100%;
					text-align:center;
					margin-bottom:30px;
				}

				.logo-desktop{
					order:1;
					padding:15px 30px;
					margin-bottom:0;
				}

				.bar-menu{
					order:2;
					text-align:left;
					padding-bottom:20px;
					display:flex;
					flex-wrap:wrap;

					& > *{
						width:100%;
					}

					#menu-topo{
						order:2;

						& > li{

							& > a{
								display:block;
								padding:10px;
								border-top:1px solid transparent;
								border-bottom:1px solid transparent;
							}

						}

					}

					.form-incrivel{
						padding:20px 10px;
						order:1;
					}

				}

				.contato-legal{
					order:3;
				}

				.redes-sociais{
					order:4;
				}

			}

		}

		&.ativo{

			.bg-menu{
				z-index:999;
				height:100%;
				opacity:1;
			}

			.nav-content{
				left:0;
			}

		}

		&.menu-fixo{

			.mbl-controls{
				position:fixed;
				top:0;
				left:0;
				width:100%;
				z-index:998;
				background-color:inherit;
				box-shadow:0 0 8px rgba(#111111, 0.6);
			}

		}

	}

}