.title-section{
    margin-top:0;
    @extend .pops-reg;
    color:#444444;

    &.title-35{
        font-size:35px;
    }

    &.title-white{
    	color:#FFFFFF;
    }

}