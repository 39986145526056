.contato-legal{
	@include get-proppertys($contatoLegal);

	&.tel-legal-topo{
		$telLegalTopo: map-get($contatoLegal, 'telLegalTopo');
		@include get-proppertys($telLegalTopo);

		a:hover{
			$hover: map-get($telLegalTopo, 'hover');
			@include get-proppertys($hover);
		}

	}

	&.ativo{

		.icon-tel-yellow,
		.icon-tel-black{
			animation:telGrow 1s infinite alternate;
		}

		.icon-msg{
			animation:msgGrow 1s infinite alternate;
		}

	}

	a{

		.icon{
			transition:transform 0.5s ease-in;
		}

	}

	@media(min-width:$min-md){
		display:inline-block;

		.icon{
			margin-bottom:0;
		}

		& + .contato-legal{
			margin-left:50px;
		}

	}

	@media(max-width:$max-sm){

		.icon{
			display:block;
			margin-left:auto;
			margin-right:auto;
			margin-bottom:10px;
		}

	}

}