.produtos-detalhes-legal{

	.row{
		margin-bottom:35px;

		@media(max-width:$max-lg){
			text-align:left !important;

			& > *{
				margin-bottom:25px;

				.titulo-center,
				.validade{
					text-align:left;
				}

			}

		}

	}

	.title-produto{
		font-size:18px;
		color:$theme;
		@extend .pops-reg;
		margin-bottom:15px;
	}

	.sabor{
		background-color:#EEEEEE;
		padding:21px 15px;
		border-radius:3px;
		min-width:65px;
		display:inline-block;

		&.nada-digitado{
			padding:0;
			min-width:0;
		}

	}

	.peso,
	.validade{
		font-size:14px;
		@extend .pops-reg;
	}

	.validade{
		text-align:center;
	}

	.titulo-center{
		text-align:center;
	}

	.nav-tabs{

	}

	.tab-content{
		margin-bottom:45px;
	}

}