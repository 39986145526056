@font-face {
    font-family: 'open_sanslight';
    src: url('#{$fonts}/opensans-light/opensans-light_0-webfont.eot');
    src: url('#{$fonts}/opensans-light/opensans-light_0-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/opensans-light/opensans-light_0-webfont.woff2') format('woff2'),
         url('#{$fonts}/opensans-light/opensans-light_0-webfont.woff') format('woff'),
         url('#{$fonts}/opensans-light/opensans-light_0-webfont.ttf') format('truetype'),
         url('#{$fonts}/opensans-light/opensans-light_0-webfont.svg#open_sanslight') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'open_sansregular';
    src: url('#{$fonts}/opensans_regular/opensans-regular-webfont.eot');
    src: url('#{$fonts}/opensans_regular/opensans-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/opensans_regular/opensans-regular-webfont.woff2') format('woff2'),
         url('#{$fonts}/opensans_regular/opensans-regular-webfont.woff') format('woff'),
         url('#{$fonts}/opensans_regular/opensans-regular-webfont.ttf') format('truetype'),
         url('#{$fonts}/opensans_regular/opensans-regular-webfont.svg#open_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'poppinsbold';
    src: url('#{$fonts}/Poppins-Bold/poppins-webfont.eot');
    src: url('#{$fonts}/Poppins-Bold/poppins-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/Poppins-Bold/poppins-webfont.woff2') format('woff2'),
         url('#{$fonts}/Poppins-Bold/poppins-webfont.woff') format('woff'),
         url('#{$fonts}/Poppins-Bold/poppins-webfont.ttf') format('truetype'),
         url('#{$fonts}/Poppins-Bold/poppins-webfont.svg#poppinsbold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'poppinsregular';
    src: url('#{$fonts}/Poppins-Regular/poppins-regular-webfont.eot');
    src: url('#{$fonts}/Poppins-Regular/poppins-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/Poppins-Regular/poppins-regular-webfont.woff2') format('woff2'),
         url('#{$fonts}/Poppins-Regular/poppins-regular-webfont.woff') format('woff'),
         url('#{$fonts}/Poppins-Regular/poppins-regular-webfont.ttf') format('truetype'),
         url('#{$fonts}/Poppins-Regular/poppins-regular-webfont.svg#poppinsregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'poppinssemibold';
    src: url('#{$fonts}/Poppins-SemiBold/poppins-semibold-webfont.eot');
    src: url('#{$fonts}/Poppins-SemiBold/poppins-semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/Poppins-SemiBold/poppins-semibold-webfont.woff2') format('woff2'),
         url('#{$fonts}/Poppins-SemiBold/poppins-semibold-webfont.woff') format('woff'),
         url('#{$fonts}/Poppins-SemiBold/poppins-semibold-webfont.ttf') format('truetype'),
         url('#{$fonts}/Poppins-SemiBold/poppins-semibold-webfont.svg#poppinssemibold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'amatic_scbold';
    src: url('#{$fonts}/AmaticSC-Bold/amaticsc-bold-webfont.eot');
    src: url('#{$fonts}/AmaticSC-Bold/amaticsc-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/AmaticSC-Bold/amaticsc-bold-webfont.woff2') format('woff2'),
         url('#{$fonts}/AmaticSC-Bold/amaticsc-bold-webfont.woff') format('woff'),
         url('#{$fonts}/AmaticSC-Bold/amaticsc-bold-webfont.ttf') format('truetype'),
         url('#{$fonts}/AmaticSC-Bold/amaticsc-bold-webfont.svg#amatic_scbold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'lovely_homeregular';
    src: url('#{$fonts}/Lovely_Home/lovely_home-webfont.eot');
    src: url('#{$fonts}/Lovely_Home/lovely_home-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/Lovely_Home/lovely_home-webfont.woff2') format('woff2'),
         url('#{$fonts}/Lovely_Home/lovely_home-webfont.woff') format('woff'),
         url('#{$fonts}/Lovely_Home/lovely_home-webfont.ttf') format('truetype'),
         url('#{$fonts}/Lovely_Home/lovely_home-webfont.svg#lovely_homeregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'poppinslight';
    src: url('#{$fonts}/Poppins-Light/poppins-light-webfont.eot');
    src: url('#{$fonts}/Poppins-Light/poppins-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/Poppins-Light/poppins-light-webfont.woff2') format('woff2'),
         url('#{$fonts}/Poppins-Light/poppins-light-webfont.woff') format('woff'),
         url('#{$fonts}/Poppins-Light/poppins-light-webfont.ttf') format('truetype'),
         url('#{$fonts}/Poppins-Light/poppins-light-webfont.svg#poppinslight') format('svg');
    font-weight: normal;
    font-style: normal;
}