.topo{
	transition:all 0.5s ease-out;

	@media(min-width:$min-lg){

		.mbl-controls{
			display:none;
		}

		.nav-content{
			padding-left:15px;
			padding-right:15px;
			margin-left:auto;
			margin-right:auto;

			.flex{
				display:flex;
				justify-content:space-between;
				align-items:center;
				flex-wrap:wrap;

				& > *{
					margin-bottom:20px;	
				}

				.bar-menu{
					margin-bottom:0;
					width:100%;
					@include flexbox;
					position:relative;
					align-items:center; 
					justify-content:center;

					&.aumentar-20{
						padding-bottom:20px;
					}

					&:before,
					&:after{
						content:'';
						display:block;
						position:absolute;
						top:0;
						width:100vw;
						height:100%;
					}

					&:before{
						left:100%;
					}

					&:after{
						right:100%;
					}

					#menu-topo{
						@include flexbox;
						flex-grow:1;
						justify-content:space-between;
						align-items:center;

						& > li{

							& > a{
								padding:20px 0;
								position:relative;

								&:before{
									content:'';
									display:block;
									position:absolute;
									top:0;
									left:0;
									width:0;
									height:6px;
									transition:width 0.5s linear;
								}

							}

						}

					}

					#form-busca{
						width:302px;
						padding-left:35px;
					}

				}

			}

		}

	}

}