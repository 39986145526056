.resultado-buscas{
	text-align:left;
	margin-bottom:40px;
	color:#444444;

	& > *{
		display:block;
		margin-bottom:20px;
	}

	.texto-cleitinho{
		margin-right:5px;
	}

	.texto-busca{
		position:relative;


		&:after{
			content:'';
			display:block;
			min-width:100%;
			min-height:1px;
			background-color:#444444;
		}

	}
	

	@media(min-width:$min-md){

		& > *{
			display:inline-block;
			margin-bottom:0;
		}

		.texto-busca{

			&:after{
			 	min-width:571px;
			}

		}

	}

}