.onda-leite{
	background-repeat:repeat-x;
	width:100%;
	animation:toddyMelhorDoqueNescau 25s ease-out infinite alternate;

	&.onda-pequena{
		min-height:53px;
	}

	&.onda-grande{
		min-height:73px;
	}

	&.onda-tamanho-custom{
		// CASO HAJA UM NOVO TAMANHO
	}

}