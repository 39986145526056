.card-produtos{
	text-align:center;
	margin-bottom:90px;

	.card-header,
	.card-desc{
		max-width:465px;
		margin-left:auto;
		margin-right:auto;
	}

	.card-header{
		margin-bottom:20px;
		position:relative;

		.card-controls{
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height:100%;
			display:flex;
			justify-content:center;
			align-items:center;
			flex-wrap:wrap;
			transition:opacity 0.5s ease-out;

			.detalhes{
				font-size:16px;
				@extend .pops-reg;
				color:#444444;
				position:relative;
				z-index:10;

				&:before{
					content:'';
					display:block;
					position:absolute;
					top:calc(50% - 4px);
					left:calc(50% - 4px);
					width:4px;
					height:4px;
					border-radius:100%;
					background-color:#FFFFFF;
					opacity:0.75;
					transition:all 0.5s linear;
					transform:scale(60);
					z-index:-10;
				}

			}

		}

	}

	.card-desc{
		font-size:20px;
		color:#444444;
		@extend .pops-reg;
		padding:0 10px;
	}

	@media(min-width:$min-lg){

		.card-controls{
			opacity:0;

			.detalhes{
				color:red;

				&:before{
					transform:scale(1) !important;
					opacity:0 !important;
				}

			}

		}

		.card-header{

			&:hover{

				.card-controls{
					opacity:1;

					.detalhes{

						&:before{
							transform:scale(60) !important;
							opacity:0.75 !important;
						}

					}

				}

			}

		}

	}

}