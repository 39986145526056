.placa{
	
	lazyimage{
		position:relative;

		.btn{
			position:absolute;
			top:calc(50% - 22px);
			left:calc(50% - 112px);
		}
		
	}

}