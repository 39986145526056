	#carousel-produtos{

		.owl-stage-outer{
			position:relative;
			z-index:5;
		}

		.owl-controls{
			display:flex;
			justify-content:center;
			align-items:center;
			position:relative;
			top:0;
			left:0;
			width:100%;
			height:100%;
			margin-bottom:90px;

			.owl-nav{
				width:100%;
				max-width:670px;

				& > *{
					position:relative;
					z-index:55;
					transition:all 0.5s linear;

					&:hover,
					&:focus{
						transform:translateY(-10px);
					}

				}

			}

		}

		@media(min-width:$min-md){

			.owl-controls{
				position:absolute;
				margin-bottom:0;
			}

		}

	}