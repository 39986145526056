.institucional-texto{
	text-align:center;
	margin-bottom:35px;

	.title-texto{
		@extend .pops-bld;
		font-size:20px;
		color:#1D1D1A;
		margin-bottom:30px;
	}

	.desc-texto{
		text-align:left;
	}

}