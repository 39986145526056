@mixin get-proppertys($map){
  @each $property, $value in $map{
    @if type-of($value) != 'map' {
      #{$property} : #{$value};
    }
    @else {
      #{$property} {
        @include get-proppertys($value)
      }
    }
  }
}