@media (min-width:$min-lg) and (max-width:$max-lg){

	.container{
		max-width:960px;
	}

	.row.flex{
		display:flex;
		flex-wrap:wrap;
		justify-content:center;
	}
	
}