.categorias-custom{
	text-align:center;
	@extend .pops-bld;
	margin-bottom:80px;

	.header-categorias-custom{
		padding:30px 20px;
	}

	.menu-categorias-custom{

		& > li{
			padding-top:10px;
			display:block;

			& > a{
				display:block;
				padding:15px 5px 20px 5px;
				position:relative;

				&:before{
					content:'';
					display:block;
					position:absolute;
					top:calc(0% - 6px);
					left:calc(50% - 71px);
					background-color:#1A1A1A;
					width:143px;
					height:1px;
				}

			}

		}

		li:first-child{

			& > a:before{

				top:calc(0% - 6px);
			}

		}

	}

	&.categorias-custom-amarelo{
		color:#000000;

		.header-categorias-custom{
			background-color:#FFED00;
		}

		ul{

			& > li{
				background-color:#FFED00;

				& > a{
					background-color:inherit;

					&:hover{
						background-color:#FFFFFF;
						color:inherit;
					}

				}

			}

		}

	}

}