.btn{
    font-size:inherit;

    &.btn-sombra{
        position:relative;

        &:before{
            content:'';
            display:block;
            width:100%;
            height:100%;
            box-shadow:3px 4px 0 0 #444444;
            position:absolute;
            top:0;
            left:0;
            transition:opacity 0.5s linear;
            opacity:1;
        }

        &:hover,
        &:focus{
            background-color:$bg-btn-padrao !important;

            &:before{
                opacity:0;
            }

            &:hover,
            &:focus{
                animation:btnManipuladorDasSombras 1s infinite alternate;
            }

        }

    }

    &.btn-warning{
        @include button-variant($color-btn-circular,$bg-btn-padrao,transparent);

        &.btn-warning-circular{
            @extend .pops-semi-bld;
            border-radius:30px;
            min-width:203px;
            padding:10px 15px;
            // font-size:15px;
            transition:all 0.5s linear;

            &:before{
                border-radius:30px;
            }

        }

        &.btn-warning-quadrado{
            @extend .pops-reg;
            min-width:179px;
            border-radius:8px;
            padding:8px 12px;
            transition:all 0.5s linear;
            outline:none;
            // font-size:14px;
            @include button-variant($color-btn-quadrado,$bg-btn-padrao,transparent);

            &:before{
                border-radius:8px;
            }

        }

    }

    &:hover{
        color:inherit;
    }

}