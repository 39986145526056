@keyframes toddyMelhorDoqueNescau{

	0%{
		background-position:center bottom;
	}

	100%{
		background-position:-70vw bottom;
	}

}