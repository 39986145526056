.form-group-custom{
	display:flex;
	justify-content:space-between;
	flex-wrap:wrap;
	align-items:center;
	@extend .form-control;
	
	label{
		font-weight:normal;
		margin-bottom:0;
	}

	.botao-fake{
		@extend button;
		padding:0 5px;
		margin-top:0 !important;
		font-weight:normal !important;
	}

	input{
		display:none;
	}

}