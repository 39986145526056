$imgs: '../imagens/estrutural';
$fonts: '../fonts';

$bg-sprite: url(#{imgs}/sprite.png) no-repeat;

$theme:#00279B;

$theme2:#FFC20C;

// BOTÃO PADRAO DO SITE
$bg-btn-padrao:#FFED00;
$color-btn-circular:#444444;
$color-btn-quadrado:#1B1B1B;


//XS
$max-xs:575.98px;

//SM
$min-sm:576px;
$max-sm:767.98px;

// MD
$min-md:768px;
$max-md:991.98px;

// LG
$min-lg:992px;
$max-lg:1199.98px;

// XL
$min-xl:1200px;

// HEADER
@import "vars/_header-variables.scss";

// CONTENT
@import "vars/_content-variables.scss";

// FOOTER
@import "vars/_footer-variables.scss";

//INTERNAL VARIABLES IF EXISTS

// COMPONENTS OF REDES SOCIAIS
@import "vars/variaveis-componentes/_redes-sociais-variables.scss";

// COMPONENTS OF FORM CUSTOM
@import "vars/variaveis-componentes/_form-custom.scss";

// COMPONENTS OF CONTATO LEGAL
@import "vars/variaveis-componentes/_contato-legal-variables.scss";

// VARIABLES OF IRTOPO
@import "vars/variaveis-componentes/_irtopo-variables.scss";