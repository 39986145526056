.nav-tab-custom{
	margin-bottom:15px;
	border-bottom:none;
	@extend .pops-reg;

	li{
		margin-bottom:0;
		text-align:center;
		float:none;
		margin-bottom:15px;

		a{
			border:none !important;
			background-color:#EEEEEE;
			color:#000000;
			cursor:pointer;
			font-size:15px;
			min-width:169px;
			margin-right:0;
			padding:12px 15px;
		}

	}

	li.active,
	li:hover{

		a{
			background-color:#EEEEEE !important;
			color:$theme !important;
		}

	}

	@media(min-width:$min-md){

		li{
			float:left;
			margin-bottom:0;
		}

		li + li{
			margin-left:30px;
		}

	}

}